const aggregateAllArticles = (articles, thoughts) => {
  let allArticles = articles.edges || []
  let allThoughts = (thoughts.edges || []).map((thought) => {
    return {
      node: {
        ...thought.node,
        // assign a type to mimic article type
        type: "Thoughts",
      },
    }
  })

  return [...allArticles, ...allThoughts].sort((entryA, entryB) => {
    const dateA = entryA.node.rawDate || entryA.node.createdAt
    const dateB = entryB.node.rawDate || entryB.node.createdAt
    return new Date(dateB) - new Date(dateA)
  })
}

const removePreviewArticles = (articles) => {
  const edges = articles.edges.filter(
    (article) => article && article.node && article.node.preview !== true
  )
  return {
    edges,
  }
}

module.exports = {
  aggregateAllArticles,
  removePreviewArticles,
}
