import React from "react"
import PropTypes from "prop-types"
import Masonry from "react-masonry-css"
import s from "./ArticleMasonry.module.scss"

const breakpointColumnsObj = {
  default: 2,
  768: 1,
}

const ArticleMasonry = ({ children }) => {
  return (
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className={s.masonry}
      columnClassName={s.column}
    >
      {children}
    </Masonry>
  )
}

ArticleMasonry.propTypes = {
  children: PropTypes.node,
}

export default ArticleMasonry
