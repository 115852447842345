import React, { Component } from "react"
import { graphql, navigate } from "gatsby"
import axios from "axios"
import { Layout } from "components/layout"
import { Meta } from "components/meta"
import { Hero } from "components/hero"
import { Section } from "components/section"
import { TagFilterWrapper } from "components/mantra/tag-filter-wrapper"
import { ArticleBox } from "components/mantra/article-box"
import { Pagination } from "components/mantra/pagination"
import { NewsletterConfirmation } from "components/mantra/newsletter-confirmation"
import ArticleMasonry from "../components/mantra/article-masonry/ArticleMasonry"
import { Row } from "../components/grid"
import ArticleColumn from "../components/mantra/article-box/ArticleColumn"
import NewsletterFooter from "../components/mantra/newsletter-footer/NewsletterFooter"
import { articlesPerPage } from "../constants/Mantra"
import { Beforeunload } from "react-beforeunload"

import {
  aggregateAllArticles,
  removePreviewArticles,
} from "../utilities/articlesHelper"

class Mantra extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentPage: props.pageContext.currentPage || 1,
      totalArticles: props.pageContext.totalArticlesLength || 0,
      loadedArticles: props.pageContext.articles?.length || 0,
      articles: props.pageContext.articles || [],
      activeTags: [],
      newsletterConfirmation: false,
      isNewsletterConfirmationRequestActive: false,
    }
  }

  getAllArticles = () => {
    const { data, pageContext } = this.props
    const { type } = pageContext

    let filteredArticles = data.articles

    if (process.env.GATSBY_ENVIRONMENT === "production") {
      filteredArticles = removePreviewArticles(data.articles)
    }

    let articles = aggregateAllArticles(filteredArticles, data.thoughts)
    if (type) {
      articles = articles.filter(
        (article) => article.node.type?.toLowerCase() === type?.toLowerCase()
      )
    }

    return articles
  }

  loadArticles = (page) => {
    const { activeTags } = this.state

    let newCurrentPage = this.state.currentPage || 1

    if (page) {
      newCurrentPage = page
    }

    const startingPage = this.getStartingPage()

    const visiblePages = startingPage
      ? Math.max(newCurrentPage - startingPage + 1, 1)
      : 1

    let articles = this.getAllArticles()

    // Filter by tag
    if (activeTags.length > 0) {
      articles = articles.filter((article) =>
        activeTags.some((activeTag) => {
          return article.node.tags?.some((tag) => {
            return tag.slug === activeTag
          })
        })
      )
    }

    const newTotalPages = Math.ceil(articles.length / articlesPerPage)
    const newTotalArticles = articles.length

    const isFirstPage = newCurrentPage === 1
    const isFirstAndOnlyPage = isFirstPage && newTotalPages === 1
    const isLastPage = newCurrentPage === newTotalPages

    let newLoadedArticles
    if (isFirstAndOnlyPage || isLastPage) {
      newLoadedArticles = newTotalArticles
    } else if (isFirstPage) {
      newLoadedArticles = articlesPerPage * newCurrentPage
    } else {
      newLoadedArticles = articlesPerPage * newCurrentPage
    }

    articles =
      articles.splice(
        (startingPage - 1) * articlesPerPage,
        articlesPerPage * visiblePages
      ) || []

    this.setState({
      currentPage: newCurrentPage,
      totalPages: newTotalPages,
      totalArticles: newTotalArticles,
      loadedArticles: Math.min(newLoadedArticles, newTotalArticles),
      articles: articles,
    })
  }

  getStartingPage = () => {
    const originalStartingPage = this.props.pageContext.currentPage || 1
    const params = new URLSearchParams(this.props.location.search)
    const startingPageParam = params.get("startingPage")
    if (startingPageParam >= 1 && startingPageParam < this.state.currentPage) {
      return startingPageParam
    }

    return originalStartingPage
  }

  loadArticlesOnClick = (e) => {
    e.preventDefault()
    const newCurrentPage = this.state.currentPage + 1
    this.loadArticles(newCurrentPage)
    const startingPage = this.getStartingPage()

    const type = this.props.pageContext.type

    if (type) {
      return
    }

    const newUrl = `/mantra/page-${newCurrentPage}/?startingPage=${startingPage}`
    window.history.pushState({}, "", newUrl)
  }

  toggleActiveTag = (tag) => {
    const { activeTags } = this.state
    let newActiveTags = activeTags
    if (newActiveTags.includes(tag)) {
      newActiveTags.splice(newActiveTags.indexOf(tag), 1)
    } else {
      newActiveTags.push(tag)
    }
    this.setState({ activeTags: newActiveTags })
    this.loadArticles()
  }

  newsletterConfirmation = () => {
    const url = new URL(this.props.location.href)
    const confirm = url.searchParams.get("confirm")

    if (confirm && confirm.length > 5) {
      // remove confirm query from url to prevent resubscribe after going back to this route
      navigate("/mantra/", { replace: true })
      this.setState({
        newsletterConfirmation: true,
        isNewsletterConfirmationRequestActive: true,
      })

      axios({
        method: "POST",
        url: `https://mailhook.martian.services/newsletter?data=${confirm}`,
      })
        .then((r) => {
          console.log(r)
          this.setState({
            isNewsletterConfirmationRequestActive: false,
          })
        })
        .catch((r) => {
          console.log(r)
          this.setState({
            isNewsletterConfirmationRequestActive: false,
          })
        })

      // Hide
      setTimeout(() => this.setState({ newsletterConfirmation: false }), 10000)
    }
  }

  componentDidMount = () => {
    const locationState = this.props.location.state

    if (locationState && locationState.tag) {
      this.toggleActiveTag(locationState.tag)
      this.setState({
        activeTags: [...this.state.activeTags, locationState.tag],
      })
    }

    this.loadArticles()

    this.newsletterConfirmation()
  }

  renderArticle = (node, i, fullHeight) => {
    return (
      <ArticleBox
        author={node.author}
        key={node.id}
        index={i}
        type={node.type}
        tags={node.tags}
        title={node.title}
        slug={node.slug}
        image={node.thumbnail || node.headerImage}
        excerpt={node.excerpt && node.excerpt.excerpt}
        bodyText={node.bodyText}
        date={node.date}
        readingTime={node.body?.fields?.readingTime}
        toggleActiveTag={this.toggleActiveTag}
        facebookLink={node.facebookLink}
        instagramLink={node.instagramLink}
        linkedInLink={node.linkedInLink}
        fullHeight={fullHeight}
      />
    )
  }

  getCanonicalUrl = (isIndex, page) => {
    if (!isIndex) {
      return "/mantra/"
    }

    if (page === 1) {
      return "/mantra/"
    }

    return `/mantra/page-${page}/`
  }

  onBeforeUnload = (event) => {
    if (this.state.isNewsletterConfirmationRequestActive) {
      event.preventDefault()
      return ""
    }
  }

  render() {
    const { pageContext, location } = this.props
    const { articles, activeTags, newsletterConfirmation } = this.state
    const { type, currentPage, totalPages } = pageContext

    const isIndex = !type
    const pageTitle = isIndex
      ? "Mantra - Venture Studio Blog"
      : `Mantra - ${type}`
    const isLastPage = currentPage === totalPages

    const heroTitle = (
      <>
        {isIndex ? (
          <>
            Our blog. Martian <mark>mantra</mark>.
          </>
        ) : (
          <>
            Martian <mark>{type}</mark>.
          </>
        )}
      </>
    )

    let articleCopy
    if (isIndex) {
      articleCopy =
        "Read the latest thoughts, insights and research articles of Martian ventures at Mantra venture studio blog."
    } else if (type === "Thoughts") {
      articleCopy =
        "Opinions, experiences, and more personal views from members of the Martian & Machine Team."
    } else if (type === "Research") {
      articleCopy =
        "A research-centered approach to discovering and understanding new paths in work and business."
    } else if (type === "Interviews") {
      articleCopy =
        "Get to know each member of the Martian team and read about their experiences inside and outside the venture world."
    } else if (type === "Insights") {
      articleCopy =
        "Best practices, structures, and tools in Tech, Startup, and beyond."
    }

    return (
      <>
        <Beforeunload onBeforeunload={this.onBeforeUnload} />
        <Layout location={location} isMantra={true}>
          <Meta
            title={pageTitle}
            description={articleCopy}
            canonical={this.getCanonicalUrl(isIndex, currentPage)}
          />
          <Hero
            title={heroTitle}
            subtitle={articleCopy}
            animationsEnabled={false}
            isMantra={true}
          />
          <TagFilterWrapper
            activeTags={activeTags}
            toggleActiveTag={this.toggleActiveTag}
          />
          <Section noTopSpacing background={"transparent"}>
            {isIndex || type === "Thoughts" ? (
              <ArticleMasonry>
                {articles.map(({ node }, i) => this.renderArticle(node, i))}
              </ArticleMasonry>
            ) : (
              <Row>
                {articles.map(({ node }, i) => {
                  return (
                    <ArticleColumn sm="12" md="6" key={node.id}>
                      {this.renderArticle(node, i, true)}
                    </ArticleColumn>
                  )
                })}
              </Row>
            )}
            <Pagination
              type={type}
              loadedArticles={this.state.loadedArticles}
              totalArticles={this.state.totalArticles}
              loadArticlesOnClick={this.loadArticlesOnClick}
              nextPage={isLastPage ? undefined : pageContext.currentPage + 1}
            />
          </Section>
          <NewsletterFooter />
          <NewsletterConfirmation visible={newsletterConfirmation} />
        </Layout>
      </>
    )
  }
}

export const mantraIndexQuery = graphql`
  {
    articles: allContentfulArticle(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          preview
          createdAt
          id
          type
          date(formatString: "DD.MM.YYYY")
          rawDate: date
          author {
            name
            avatar {
              title
              fixed(width: 48) {
                src
                srcSet
              }
            }
          }
          title
          slug
          tags {
            name
            slug
          }
          quote
          headerImage {
            title
            fixed(width: 600, quality: 75) {
              aspectRatio
              base64
              height
              src
              srcSet
              srcWebp
              width
              srcSetWebp
            }
          }
          thumbnail {
            title
            fixed(width: 600, quality: 75) {
              aspectRatio
              base64
              height
              src
              srcSet
              srcWebp
              width
              srcSetWebp
            }
          }
          excerpt {
            excerpt
          }
          body {
            json
            fields {
              readingTime
            }
          }
        }
      }
    }
    thoughts: allContentfulThought(sort: { order: DESC, fields: createdAt }) {
      edges {
        node {
          id
          createdAt
          facebookLink
          instagramLink
          linkedInLink
          title
          bodyText
          tags {
            name
            slug
          }
          author {
            name
            avatar {
              fixed(width: 100) {
                src
              }
              title
            }
          }
          sys {
            contentType {
              sys {
                id
              }
            }
          }
        }
      }
    }
  }
`

export default Mantra
