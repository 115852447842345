import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import cx from "classnames"
import s from "./TagFilter.module.scss"

export const TagFilter = (props) => {
  const data = useStaticQuery(graphql`
    {
      tags: allContentfulArticleTag(sort: { fields: order, order: ASC }) {
        edges {
          node {
            name
            slug
            order
          }
        }
      }
    }
  `)

  return (
    <div className={s.tags}>
      {data.tags.edges.map(({ node: tag }, i) => (
        <button
          key={`tag-${i}`}
          onClick={() => props.toggleActiveTag(tag.slug)}
          className={cx(s.tag, {
            [s.tagActive]: props.activeTags.includes(tag.slug),
          })}
        >
          <div className={s.tagHolder}>{tag.name}</div>
        </button>
      ))}
    </div>
  )
}
