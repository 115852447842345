import React from "react"
import s from "./NewsletterConfirmation.module.scss"
import iconCheckmark from "../../../assets/icons/checkmark.svg"

export const NewsletterConfirmation = (props) => {
  if (props.visible) {
    return (
      <div className={s.wrapper}>
        <img src={iconCheckmark} alt="✓" className={s.icon} />
        You have successfully subscribed to the Martian Mantra. Welcome!
      </div>
    )
  } else {
    return null
  }
}
