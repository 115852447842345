import React from "react"
import { Column } from "../../grid"
import cx from "classnames"
import s from "./ArticleColumn.module.scss"

const ArticleColumn = ({ className, children, ...rest }) => {
  return (
    <Column sm="12" md="6" className={cx(s.column, className)} {...rest}>
      {children}
    </Column>
  )
}

export default ArticleColumn
