import React, { useEffect, useState } from "react"
import cx from "classnames"
import styles from "./Pagination.module.scss"
import { Button, ButtonLink } from "components/button"
import { useBreakpoint } from "../../../hooks/useBreakpoint"

export const Pagination = (props) => {
  const {
    loadedArticles,
    totalArticles,
    loadArticlesOnClick,
    nextPage,
    type,
  } = props
  const [isMounted, setIsMounted] = useState(false)
  useEffect(() => {
    setIsMounted(true)
  }, [])

  const hasMoreArticles = loadedArticles < totalArticles
  const isLoadMoreButtonVisible = hasMoreArticles && (nextPage || type)

  const breakpoint = useBreakpoint()
  const isFull = breakpoint === "xs"

  return (
    <div className={styles.wrapper}>
      {totalArticles !== 0 && (
        <p
          className={cx({
            [styles.titleWithButton]: isLoadMoreButtonVisible,
          })}
        >
          You’ve viewed {loadedArticles} out of{" "}
          <strong>{totalArticles} mantras</strong>
        </p>
      )}
      {totalArticles === 0 && (
        <p
          className={cx({
            [styles.titleWithButton]: isLoadMoreButtonVisible,
          })}
        >
          <strong>Whoops!</strong> No articles here.
        </p>
      )}
      {isLoadMoreButtonVisible &&
        (isMounted ? (
          <Button
            full={isFull}
            variant="primary"
            onClick={(e) => {
              loadArticlesOnClick(e)
            }}
          >
            Load More
          </Button>
        ) : (
          <ButtonLink
            full={isFull}
            variant="primary"
            to={`/mantra/page-${nextPage}/`}
          >
            Load More
          </ButtonLink>
        ))}
    </div>
  )
}
